// Create Email -> Id fetch service
// Validate for changes in email and fetch id
const serverURL =
	'https://europe-west3-heyflow-clients.cloudfunctions.net/windmill-validate';

let emailValidated = false;
let oldEmail, newEmail;
let shadowRootHeyflow = document;

const discontinueOperation = () => {
	const buttonElement = shadowRootHeyflow
		.querySelector('section.visible')
		.querySelector('button.continue');
	buttonElement.style.background = 'red';
	buttonElement.querySelector('span').innerHTML = 'Contact Windmill Microlending to Continue!';
	buttonElement.removeAttribute('onclick');
};

const verifyLoanExists = async (email) => {
	const verificationURL = `${serverURL}?email=${email}`;
	const response = await axios.get(verificationURL);
	if (response.data.loans) {
		discontinueOperation();
		// swal(
		// 	'You already have applied for a loan! If this is not the case, please contact Team Windmill Admin!',
		// );
		swal(
			'Alert!',
			'You currently have an active loan application with Windmill Microlending. If you’d like to increase your loan amount, please contact us at info@teamwindmill.org or 1-855-423-2262 to discuss your options.',
			'error',
		);
		return true;
	} else return false;
};

const validateEmail = async (email) => {
	console.log('Email to check', email);
	const data = {
		email: email,
	};
	const response = await axios.post(serverURL, data);
	if (response.data.customerId) return response.data.customerId;
	else return '';
};

// Helper Function to add entry to the specific field.
const addEntryToField = (data, fieldName) => {
	if (data && fieldName) {
		shadowRootHeyflow.querySelector(`[data-variable="${fieldName}"]`).value =
			data;
	}
};

const verifyEmailData = async (email) => {
	newEmail = email;
	console.log('Email: ', email);
	console.log('Email Validated: ', emailValidated);
	console.log('Old Email: ', oldEmail);
	if (email != '' && !emailValidated && newEmail != oldEmail) {
		const customerId = await validateEmail(email);
		emailValidated = true;
		oldEmail = email;
		addEntryToField(customerId, 'CustomerId');
		const response = await verifyLoanExists(email);
	}
};

window.addEventListener('heyflow-change', async (event) => {
	const fieldsData = event.detail.fields;
	console.log(fieldsData);

	fieldsData.map(async (item) => {
		if (item.variable == 'Email') {
			let email = item.value;
			await verifyEmailData(email);
		}
	});
});

window.addEventListener('heyflow-init', async () => {
	console.log('Heyflow Wrapper: ', document.querySelector('heyflow-wrapper'));
	if (document.querySelector('heyflow-wrapper')?.shadowRoot) {
		shadowRootHeyflow = document.querySelector('heyflow-wrapper')?.shadowRoot;
	}
	console.log(shadowRootHeyflow);
	console.info('Heyflow Initialised');

	const initialEmail = shadowRootHeyflow.querySelector(
		`[data-variable="Email"]`,
	).value;
	console.log('Initial Email: ', initialEmail);
	if (initialEmail != '') {
		await verifyEmailData(initialEmail);
		const response = await verifyLoanExists(initialEmail);
	}
});
